import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { detectLanguage, translateText } from '../utils/translator';

// Create a context for language management
export const LanguageContext = createContext({
  currentLanguage: 'fr',
  setLanguage: () => {},
  translateContent: async () => {},
  detectLanguageContent: async () => {},
});

export const LanguageProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState('fr');
  const translations = useRef({}); // Use useRef for caching translations

  // Function to set language and update localStorage
  const setLanguage = (lang) => {
    setCurrentLanguage(lang);
    localStorage.setItem('websiteLanguage', lang);
  };

  // Function to detect language of the text and set it as the current language
  const detectLanguageContent = async (text) => {
    try {
      const detectedLang = await detectLanguage(text);
      if (detectedLang) {
        setCurrentLanguage(detectedLang);
        localStorage.setItem('websiteLanguage', detectedLang);  // Save detected language in localStorage
      }
    } catch (error) {
      console.error('Language detection error:', error);
    }
  };

  // Function to translate content
  // Function to translate content
const translateContent = async (text, targetLang = currentLanguage) => {
  // Check if translation already exists in cache
  const cacheKey = `${text}-${targetLang}`;
  if (translations.current[cacheKey]) {
    return translations.current[cacheKey];
  }

  // Check for specific word translations
  const upperText = text.trim().toUpperCase();
  if (upperText === 'ACCUEIL') {
    if (targetLang === 'en') {
      return 'HOME'; // Translate to 'HOME' for English
    }
    return 'ACCUEIL'; // Default to original for other languages
  }

  // Perform translation
  try {
    const translatedText = await translateText(text, targetLang);

    // Cache the translation
    translations.current[cacheKey] = translatedText;

    return translatedText;
  } catch (error) {
    console.error('Translation error:', error);
    return text; // Fallback to original text if translation fails
  }
};

  

  // Load language from localStorage on initial load
  useEffect(() => {
    const savedLanguage = localStorage.getItem('websiteLanguage');
    if (savedLanguage) {
      setCurrentLanguage(savedLanguage);
    }
  }, []);

  return (
    <LanguageContext.Provider value={{ 
      currentLanguage, 
      setLanguage, 
      translateContent,
      detectLanguageContent 
    }}>
      {children}
    </LanguageContext.Provider>
  );
};

// Custom hook for easy context usage
export const useLanguage = () => useContext(LanguageContext);
