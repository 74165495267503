import axios from 'axios';
const { v4: uuidv4 } = require('uuid');

// Replace with your API key and endpoint
const apiKey = '4N2eEXWxhq7s4hziHeKu8ssQL3oNHeevNvhCvZzSY5yhiX20n85wJQQJ99ALACREanaXJ3w3AAAbACOGOoWj';
const endpoint = 'https://api.cognitive.microsofttranslator.com/';

// Location required for regional resources (replace with your resource location)
const location = 'canadaeast';

// Set up the headers for authentication
const headers = {
  'Ocp-Apim-Subscription-Key': apiKey,
  'Content-Type': 'application/json',
  'Ocp-Apim-Subscription-Region': location, // e.g. 'eastus'
  'X-ClientTraceId': uuidv4().toString(),
};

// Function to detect language
export const detectLanguage = async (text) => {
  try {
    const response = await axios.post(
      `${endpoint}detect?api-version=3.0`,
      [{ Text: text }],
      { headers }
    );
    return response.data[0].language; // Returns the detected language code
  } catch (error) {
    console.error('Error detecting language:', error);
    return null;
  }
};

// Function to translate text
export const translateText = async (text, targetLanguage) => {
  try {
    const response = await axios.post(
      `${endpoint}translate?api-version=3.0&to=${targetLanguage}`,
      [{ Text: text }],
      { headers }
    );
    return response.data[0].translations[0].text; // Translated text
  } catch (error) {
    console.error('Error translating text:', error);
    return null;
  }
};


